import { NotFoundError } from '~/errors/notFoundError';
import { RentalApplicationQuestionsDto } from '~/pages/rental-application/steps/QuestionsStep';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import {
  RentalApplicationIntents,
  RentalApplication,
  FileDescriptor,
  HydratedRentalApplicationFileDto,
  ScreeningQuestion,
  TransUnionScreening,
  TransUnionAnswerSheet,
  TransUnionScreeningStatus,
} from '~/types/RentalApplication';
import { Gateway } from './gateway';
import { companyIdAttachingNetworkManager } from './network/companyIdAttachingNetworkManager';
import { MagicRequest, RequestMethod } from './network/magicRequest';

const RENTAL_APPLICATIONS_URL = '/api/rental-applications';

class RentalApplicationGateway extends Gateway {
  async getRentalApplication(credentials: RentalApplicationCredentials): Promise<RentalApplication | undefined> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/${credentials.password}`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    try {
      const response = await this.sendRequest(request);
      const json = await response.json();
      return this.createRentalApplicationFromJson(json);
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        throw error;
      }
    }
    return undefined;
  }

  async getApplicationQuestions(): Promise<RentalApplicationQuestionsDto> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/questions`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    return await response.json();
  }

  async createApplication(): Promise<RentalApplication> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
      body: JSON.stringify({}),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createRentalApplicationFromJson(json);
  }

  async updateApplication(application: RentalApplication): Promise<RentalApplication> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${application.credentials.id}/${application.credentials.password}`,
      method: RequestMethod.PUT,
      headers: this.createCommonHeaders(),
      body: this.createRentalApplicationUpdateRequestBody(application),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createRentalApplicationFromJson(json);
  }

  private createRentalApplicationUpdateRequestBody = (application: RentalApplication) => {
    const questionDictionary: { [key: string]: boolean } | undefined = application.questions?.reduce((acc, current) => {
      acc[current.question] = current.answer;
      return acc;
    }, {} as { [key: string]: boolean });

    return JSON.stringify({
      firstName: application.firstName,
      lastName: application.lastName,
      email: application.email,
      phone: application.phone,
      ssn: application.ssn,
      tenantId: application.tenantId,
      dateOfBirth: application.dateOfBirth,
      applyingWith: application.applyingWith,
      interestedUnitIds: application.interestedUnits,
      desiredMoveInDate: application.desiredMoveInDate,
      maritalStatus: application.maritalStatus,
      driversLicense: application.driversLicense,
      incomeInformation: {
        annualIncome: application?.annualIncome,
      },
      pets: application.pets,
      residentialHistory: application.residentialHistory?.map((history) => ({
        ...history,
        landlordName: this.nullOutFieldIfEmpty(history.landlordName),
        moveOutDate: this.nullOutFieldIfEmpty(history.moveOutDate),
        landlordPhone: this.nullOutFieldIfEmpty(history.landlordPhone),
        reasonForLeaving: this.nullOutFieldIfEmpty(history.reasonForLeaving),
        address: {
          ...history.address,
          streetAddress2: this.nullOutFieldIfEmpty(history.address?.streetAddress2),
        },
      })),
      emergencyContact: application.emergencyContact,
      employment: application.employmentHistory?.map((history) => ({
        ...history,
        phone: this.nullOutFieldIfEmpty(history.phone),
        endDate: this.nullOutFieldIfEmpty(history.endDate),
      })),
      questions: questionDictionary,
      comments: application.comments,
    });
  };

  private nullOutFieldIfEmpty = (field?: string): string | undefined => {
    return field === undefined || field === '' ? undefined : field;
  };

  private createRentalApplicationFromJson = (json: any): RentalApplication => {
    return {
      credentials: {
        id: json.id,
        password: json.password,
      },
      firstName: json.firstName,
      lastName: json.lastName,
      email: json.email,
      phone: json.phone,
      ssn: json.ssn,
      tenantId: json.tenantId,
      dateOfBirth: json.dateOfBirth,
      applyingWith: json.applyingWith,
      interestedUnits: this.getUnitIdsFromJson(json),
      desiredMoveInDate: json.desiredMoveInDate,
      maritalStatus: json.maritalStatus,
      driversLicense: json.driversLicense,
      annualIncome: json.incomeInformation ? json.incomeInformation.annualIncome : undefined,
      pets: json.pets,
      residentialHistory: json.residentialHistory,
      emergencyContact: json.emergencyContact,
      employmentHistory: json.employment,
      comments: json.comments,
      questions: this.getQuestionsFromJson(json),
      createdAt: json.createdAt,
      updatedAt: json.updatedAt,
      files: this.remapFiles(json.files),
      isDraft: json.draft,
      submittedAt: json.submittedAt,
      requiresPayment: !json.screeningPaid,
      screeningStatus: json.screeningStatus,
      newFiles: [],
    };
  };

  private getQuestionsFromJson = (json: any): ScreeningQuestion[] => {
    if (!json.questions) {
      return [];
    }
    return Object.entries(json.questions).map(([key, value]) => {
      const question: string = key;
      const answer: boolean = value as boolean;
      return { question, answer };
    });
  };

  private getUnitIdsFromJson = (json: any): string[] => {
    const ids = json.interests.map((interest: any) => interest.unit.id);
    return ids;
  };

  private remapFiles = (files: HydratedRentalApplicationFileDto[]): HydratedRentalApplicationFileDto[] => {
    return files.map((file) => {
      const fileUrl = file.fileUrl ? '/api' + file.fileUrl : '';
      const thumbUrl = file.thumbUrl ? '/api' + file.thumbUrl : fileUrl;
      return {
        ...file,
        fileUrl,
        thumbUrl,
      };
    });
  };

  async uploadRentalApplicationFile(credentials: RentalApplicationCredentials, fileDescriptor: FileDescriptor): Promise<RentalApplication> {
    const FILE_UPLOAD_URL = `${RENTAL_APPLICATIONS_URL}/${credentials.id}/files`;
    const formData = new FormData();
    formData.append('File', fileDescriptor.file);
    formData.append('Type', fileDescriptor.type);
    formData.append('Password', credentials.password);
    const request: MagicRequest = {
      url: FILE_UPLOAD_URL,
      method: RequestMethod.POST,
      headers: new Headers(),
      body: formData,
    };
    const response: Response = await this.sendRequest(request);
    return await response.json();
  }

  async lockRentalApplication(credentials: RentalApplicationCredentials): Promise<void> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/lock/${credentials.password}`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    await this.sendRequest(request);
  }

  async getPaymentIntentForRentalApplication(credentials: RentalApplicationCredentials): Promise<RentalApplicationIntents> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/payment/${credentials.password}`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    return await response.json();
  }

  async fetchRentalApplicationPaymentStatus(credentials: RentalApplicationCredentials): Promise<boolean> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/payment/${credentials.password}`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return json.paid;
  }

  async startTransUnionScreening(credentials: RentalApplicationCredentials): Promise<TransUnionScreening> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/screening/${credentials.password}`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return {
      status: json.status,
      transunionScreeningId: json.transunionScreeningRequestId,
      transunionScreeningRequestRenterId: json.transunionScreeningRequestRenterId,
    };
  }

  async getTransUnionScreeningStatus(credentials: RentalApplicationCredentials): Promise<TransUnionScreening> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/screening/${credentials.password}`,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return {
      status: json.status,
      transunionScreeningId: json.transunionScreeningRequestId,
      transunionScreeningRequestRenterId: json.transunionScreeningRequestRenterId,
    };
  }

  async getTransUnionScreeningQuestions(credentials: RentalApplicationCredentials): Promise<TransUnionScreening> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/exam/${credentials.password}`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return {
      status: json.status,
      transunionScreeningId: json.transunionScreeningRequestId,
      transunionScreeningRequestRenterId: json.transunionScreeningRequestRenterId,
      examId: json.examId,
      questions: json.questions,
    };
  }

  async sendTransUnionScreeningAnswers(
    credentials: RentalApplicationCredentials,
    answers: TransUnionAnswerSheet
  ): Promise<TransUnionScreeningStatus> {
    const request: MagicRequest = {
      url: `${RENTAL_APPLICATIONS_URL}/${credentials.id}/answers/${credentials.password}`,
      method: RequestMethod.POST,
      headers: this.createCommonHeaders(),
      body: JSON.stringify(answers),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return json.screeningStatus;
  }
}

export const rentalApplicationGateway = new RentalApplicationGateway(companyIdAttachingNetworkManager);
