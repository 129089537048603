import {
  ADDRESS_IS_INVALID,
  ANNUAL_INCOME_IS_INVALID,
  CITY_IS_INVALID,
  COUNTRY_IS_INVALID,
  DATE_OF_BIRTH_IS_INVALID,
  EMAIL_IS_INVALID,
  EMPLOYER_NAME_IS_INVALID,
  END_DATE_IS_INVALID,
  FIRST_NAME_IS_INVALID,
  LANDLORD_PHONE_NUMBER_IS_INVALID,
  LAST_NAME_IS_INVALID,
  MOVE_IN_DATE_IS_INVALID,
  PHONE_NUMBER_IS_INVALID,
  POSITION_TITLE_IS_INVALID,
  REASON_FOR_LEAVING_IS_INVALID,
  RENT_IS_INVALID,
  SSN_IS_INVALID,
  START_DATE_IS_INVALID,
  STATE_IS_INVALID,
  STREET_ADDRESS_1_IS_INVALID,
  STREET_ADDRESS_2_IS_INVALID,
  YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT,
  ZIP_CODE_IS_INVALID,
} from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { useLocalization } from '~/hooks/useLocalization';
import { StripeInfo, User } from '~/state/mainAppState';
import { Address } from '~/types/Address';
import { PresentableUnit } from '~/types/PresentableUnit';
import {
  FileType,
  RentalApplicationErrorCode,
  RentalHistoryErrorCode,
  EmploymentHistoryErrorCode,
  ScreeningQuestion,
  TransUnionScreeningStatus,
  TransUnionScreening,
} from '~/types/RentalApplication';
import { Unit } from '~/types/Unit';
import { statesAndProvincesMap } from '~/use-cases/rental-applications/countries';

export interface SelectionValue {
  value: string;
  label: string;
  isSelected: boolean;
}

export interface PresentableRentalApplication {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  ssn?: string;
  governmentIdFiles?: PresentableFile[];
  bankStatementFiles?: PresentableFile[];
  annualIncome?: string;
  url?: string;
  rentalHistory?: PresentableRentalHistory[];
  employmentHistory?: PresentableEmploymentInformation[];
  errors?: PresentableRentalApplicationErrors;
  rentalHistoryErrors?: PresentableRentalHistoryError[];
  employmentHistoryErrors?: PresentableEmploymentHistoryError[];
  unitsOfInterest?: PresentableUnit[];
  questions?: ScreeningQuestion[];
  hasAcceptedMagicDoorTerms?: boolean;
  hasAcceptedTransUnionTerms?: boolean;
  stripeInfo?: StripeInfo;
  isPaid?: boolean;
  isLocked?: boolean;
  transUnionScreening?: TransUnionScreening;
}

export interface PresentableTransUnionScreening {
  questions: ScreeningQuestion[];
  screeningStatus: TransUnionScreeningStatus;
}

export interface PresentableEmploymentInformation {
  name: string;
  phone: string;
  position: string;
  salary?: string;
  startDate?: string;
  endDate?: string;
}

export interface PresentableRentalHistory {
  address?: Address;
  landlordName?: string;
  landlordPhone?: string;
  rent?: string;
  moveInDate?: string;
  moveOutDate?: string;
  reasonForLeaving?: string;
  countries: SelectionValue[];
  states: SelectionValue[];
  selectedCounty?: string;
  selectedState?: string;
}

export interface PresentableRentalApplicationErrors {
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  phoneError: string;
  dateOfBirthError: string;
  ssnError: string;
  annualIncomeError: string;
  magicDoorLicenseError: string;
  transUnionLicenseError: string;
  hasApplicantInfoError: boolean;
  hasRentalHistoryError: boolean;
  hasEmploymentHistoryError: boolean;
  hasLicenseAgreementErrors: boolean;
}

export interface PresentableRentalHistoryError {
  rentalHistoryAddressError: string;
  rentalHistoryStreetAddress1Error: string;
  rentalHistoryStreetAddress2Error: string;
  rentalHistoryReasonForLeavingError: string;
  rentalHistoryCityError: string;
  rentalHistoryStateError: string;
  rentalHistoryZipCodeError: string;
  rentalHistoryCountryError: string;
  rentalHistoryLandlordPhoneError: string;
  rentalHistoryRentError: string;
  rentalHistoryMoveInDateError: string;
}

export interface PresentableEmploymentHistoryError {
  employmentInformationNameError: string;
  employmentInformationPhoneError: string;
  employmentInformationPositionError: string;
  employmentInformationStartDateError: string;
  employmentInformationEndDateError: string;
}

export interface PresentableFile {
  id: string;
  path: string;
  name: string;
  type: string;
  isImage: boolean;
}

export class RentalApplicationPresenter extends Presenter<PresentableRentalApplication> {
  protected createModel = (state: User): PresentableRentalApplication | undefined => {
    const rentalApplication = state.rentalApplication.application;
    return {
      firstName: rentalApplication?.firstName,
      lastName: rentalApplication?.lastName,
      email: rentalApplication?.email,
      phoneNumber: rentalApplication?.phone,
      dateOfBirth: rentalApplication?.dateOfBirth,
      ssn: rentalApplication?.ssn,
      governmentIdFiles: this.getFilesOfType(state, FileType.Identification),
      bankStatementFiles: this.getFilesOfType(state, FileType.BankStatement),
      unitsOfInterest: this.getUnitsOfInterest(state),
      annualIncome: rentalApplication?.annualIncome ? currency(rentalApplication?.annualIncome) : undefined,
      url: `/rental-applications/${rentalApplication?.credentials.id}/${rentalApplication?.credentials.password}`,
      rentalHistory: this.getRentalHistory(state),
      employmentHistory: (rentalApplication?.employmentHistory || []).map((history) => {
        return {
          name: history.name,
          phone: history.phone,
          position: history.position,
          salary: currency(history.salary),
          startDate: history.startDate,
          endDate: history.endDate,
        };
      }),
      questions: rentalApplication?.questions || [],
      hasAcceptedMagicDoorTerms: rentalApplication?.hasAcceptedMagicDoorTerms || false,
      hasAcceptedTransUnionTerms: rentalApplication?.hasAcceptedTransUnionTerms || false,
      errors: this.createPresentableRentalApplicationErrors(state),
      rentalHistoryErrors: this.createRentalHistoryErrors(state),
      employmentHistoryErrors: this.createEmploymentHistoryErrors(state),
      stripeInfo: state.rentalApplication.stripe,
      isPaid: state.rentalApplication.isPaid || !state.rentalApplication.application?.requiresPayment,
      isLocked: !state.rentalApplication.application?.isDraft,
      transUnionScreening: state.rentalApplication.transUnionScreening,
    };
  };

  private getRentalHistory = (state: User): PresentableRentalHistory[] => {
    const history = state.rentalApplication.application?.residentialHistory;
    if (!history || history.length === 0) {
      const countries = this.getCountries(history?.[0]?.address?.country);
      const states = this.getStates(history?.[0]?.address?.country, history?.[0]?.address?.state);
      return [
        {
          countries,
          states,
          selectedCounty: countries.find((country) => country.isSelected)?.value,
          selectedState: states.find((state) => state.isSelected)?.value,
        },
      ];
    }
    return history.map((rentalHistory) => {
      const countries = this.getCountries(rentalHistory.address?.country);
      const states = this.getStates(rentalHistory.address?.country, rentalHistory.address?.state);
      return {
        ...rentalHistory,
        rent: rentalHistory.rent ? currency(rentalHistory.rent) : '',
        countries,
        states,
        selectedCounty: countries.find((country) => country.isSelected)?.value,
        selectedState: states.find((state) => state.isSelected)?.value,
      };
    });
  };

  private getCountries(selectedCountry?: string): SelectionValue[] {
    const countries: SelectionValue[] = [];
    statesAndProvincesMap.forEach((_, country) => {
      countries.push({
        value: country,
        label: country,
        isSelected: selectedCountry ? country === selectedCountry : country === 'USA',
      });
    });
    return countries;
  }

  private getStates(selectedCountry?: string, selectedState?: string): SelectionValue[] {
    const states: SelectionValue[] = [];
    const targetCountry = selectedCountry || 'USA';
    statesAndProvincesMap.get(targetCountry)?.forEach((state, index) => {
      states.push({
        value: state,
        label: state,
        isSelected: selectedState ? state === selectedState : index === 0,
      });
    });
    return states;
  }

  private getUnitsOfInterest = (state: User): PresentableUnit[] => {
    return (
      state.unitsListing?.map((unit) => {
        return {
          id: unit?.id || '',
          name: unit?.name || '',
          bedrooms: unit?.beds?.toLocaleString() || '',
          bathrooms: unit?.baths?.toLocaleString() || '',
          address: this.getAddressString(unit.address),
          isSelected: state.rentalApplication.application?.interestedUnits?.includes(unit.id),
          area: unit?.unitSizeSqft?.toLocaleString() || '',
          isAvailable: unit.isAvailable,
          images: unit?.images?.map((image) => image.signedThumbUrl || '') || [],
        };
      }) || []
    );
  };

  private getAddressString = (address?: Address): string => {
    if (!address) {
      return '';
    }
    let addressString = address.streetAddress1 || '';
    if (address.streetAddress2 && address.streetAddress2.length > 0) {
      addressString += `, ${address.streetAddress2}`;
    }
    if (address.city && address.city.length > 0) {
      addressString += `, ${address.city}`;
    }
    if (address.state && address.state.length > 0) {
      addressString += `, ${address.state}`;
    }
    return addressString;
  };

  private getThumbnail = (unit?: Unit): string => {
    if (unit?.images && unit?.images.length > 0) {
      return unit.images[0].signedThumbUrl || '';
    }
    return '';
  };

  private getThumbnailStrings = (unit?: Unit): string[] => {
    return unit?.images?.map((image) => image.signedThumbUrl).filter((url): url is string => url !== undefined) || [];
  };

  private getFilesOfType = (state: User, type: FileType): PresentableFile[] => {
    const rentalApplication = state.rentalApplication.application;
    let files: PresentableFile[] = [];
    if (rentalApplication?.files) {
      files = rentalApplication.files
        .filter((file) => file.type === type)
        .map((file) => {
          return {
            id: file.fileId,
            name: file.fileName,
            type: file.type,
            path: file.thumbUrl ? file.thumbUrl : file.fileUrl,
            isImage: file.contentType.includes('image'),
          } as PresentableFile;
        });
    }
    if (rentalApplication?.newFiles) {
      files = [
        ...files,
        ...rentalApplication.newFiles
          .filter((file) => file.type === type)
          .map((file) => {
            return {
              id: file.id,
              name: file.name,
              type: file.type,
              path: URL.createObjectURL(file.file),
              isImage: file.isImage,
            };
          }),
      ];
    }
    return files;
  };
  private hasApplicantInfoError = (state: User): boolean => {
    return (
      state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidFirstName) ||
      state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidLastName) ||
      state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidEmail) ||
      state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidPhone) ||
      state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidSSN) ||
      state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidDateOfBirth)
    );
  };

  private createPresentableRentalApplicationErrors = (state: User): PresentableRentalApplicationErrors => {
    const { t } = useLocalization();
    return {
      firstNameError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidFirstName) ? t(FIRST_NAME_IS_INVALID) : '',
      lastNameError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidLastName) ? t(LAST_NAME_IS_INVALID) : '',
      emailError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidEmail) ? t(EMAIL_IS_INVALID) : '',
      phoneError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidPhone) ? t(PHONE_NUMBER_IS_INVALID) : '',
      dateOfBirthError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidDateOfBirth)
        ? t(DATE_OF_BIRTH_IS_INVALID)
        : '',
      annualIncomeError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidAnnualIncome)
        ? t(ANNUAL_INCOME_IS_INVALID)
        : '',
      ssnError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidSSN) ? t(SSN_IS_INVALID) : '',
      magicDoorLicenseError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidMagicDoorAgreement)
        ? t(YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT)
        : '',
      transUnionLicenseError: state.rentalApplication.errors?.includes(RentalApplicationErrorCode.InvalidTransUnionAgreement)
        ? t(YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT)
        : '',
      hasApplicantInfoError: this.hasApplicantInfoError(state),
      hasRentalHistoryError: this.hasRentalHistoryError(state),
      hasEmploymentHistoryError: this.hasEmploymentHistoryError(state),
      hasLicenseAgreementErrors: this.hasLicenseAgreementErrors(state),
    };
  };

  private createRentalHistoryErrors = (state: User): PresentableRentalHistoryError[] => {
    const result: PresentableRentalHistoryError[] = [];
    const { t } = useLocalization();
    state.rentalApplication.rentalHistoryErrors.forEach((historyError) => {
      const errorObject: PresentableRentalHistoryError = {
        rentalHistoryAddressError: historyError.includes(RentalHistoryErrorCode.InvalidAddress) ? t(ADDRESS_IS_INVALID) : '',
        rentalHistoryStreetAddress1Error: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1)
          ? t(STREET_ADDRESS_1_IS_INVALID)
          : '',
        rentalHistoryStreetAddress2Error: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2)
          ? t(STREET_ADDRESS_2_IS_INVALID)
          : '',
        rentalHistoryCityError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryCity) ? t(CITY_IS_INVALID) : '',
        rentalHistoryReasonForLeavingError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryReansonForLeaving)
          ? t(REASON_FOR_LEAVING_IS_INVALID)
          : '',
        rentalHistoryStateError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryState) ? t(STATE_IS_INVALID) : '',
        rentalHistoryZipCodeError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryZipCode) ? t(ZIP_CODE_IS_INVALID) : '',
        rentalHistoryCountryError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryCountry) ? t(COUNTRY_IS_INVALID) : '',
        rentalHistoryLandlordPhoneError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone)
          ? t(LANDLORD_PHONE_NUMBER_IS_INVALID)
          : '',
        rentalHistoryRentError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryRent) ? t(RENT_IS_INVALID) : '',
        rentalHistoryMoveInDateError: historyError.includes(RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate)
          ? t(MOVE_IN_DATE_IS_INVALID)
          : '',
      };
      result.push(errorObject);
    });
    return result;
  };

  private createEmploymentHistoryErrors = (state: User): PresentableEmploymentHistoryError[] => {
    const result: PresentableEmploymentHistoryError[] = [];
    const { t } = useLocalization();
    state.rentalApplication.employmentHistoryErrors.forEach((historyError) => {
      const errorObject: PresentableEmploymentHistoryError = {
        employmentInformationNameError: historyError.includes(EmploymentHistoryErrorCode.InvalidNameError)
          ? t(EMPLOYER_NAME_IS_INVALID)
          : '',
        employmentInformationPhoneError: historyError.includes(EmploymentHistoryErrorCode.InvalidPhoneError)
          ? t(PHONE_NUMBER_IS_INVALID)
          : '',
        employmentInformationPositionError: historyError.includes(EmploymentHistoryErrorCode.InvalidPositionError)
          ? t(POSITION_TITLE_IS_INVALID)
          : '',
        employmentInformationStartDateError: historyError.includes(EmploymentHistoryErrorCode.InvalidStartDateError)
          ? t(START_DATE_IS_INVALID)
          : '',
        employmentInformationEndDateError: historyError.includes(EmploymentHistoryErrorCode.InvalidEndDateError)
          ? t(END_DATE_IS_INVALID)
          : '',
      };
      result.push(errorObject);
    });
    return result;
  };

  private hasRentalHistoryError = (state: User): boolean => {
    return (
      state.rentalApplication.rentalHistoryErrors.length > 0 &&
      state.rentalApplication.rentalHistoryErrors.some((subarray) => subarray.length > 0)
    );
  };

  private hasEmploymentHistoryError = (state: User): boolean => {
    return (
      (state.rentalApplication.employmentHistoryErrors.length > 0 &&
        state.rentalApplication.employmentHistoryErrors.some((subarray) => subarray.length > 0)) ||
      state.rentalApplication.errors.includes(RentalApplicationErrorCode.InvalidAnnualIncome)
    );
  };

  private hasLicenseAgreementErrors = (state: User): boolean => {
    return (
      state.rentalApplication.errors.includes(RentalApplicationErrorCode.InvalidTransUnionAgreement) ||
      state.rentalApplication.errors.includes(RentalApplicationErrorCode.InvalidMagicDoorAgreement)
    );
  };
}
