import { PresentableEmploymentInformation, PresentableRentalHistory } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { Address } from './Address';

export interface updateRentalApplicationParams {
  rentalApplicationId: string;
  rentalApplicationPW: string;
  applicationData: RentalApplicationData;
}

export interface RentalApplicationData {
  name: string;
  email: string;
  phone: string;
  ssn: string;
  tenantId: string;
  applyingWith: string[];
  interestedUnitIds: string[];
  desiredMoveInDate: string;
  maritalStatus: MaritalStatus;
  driversLicense: DriversLicense;
  incomeInformation: IncomeInformation;
  pets: Pet[];
  residentialHistory: RentalHistory[];
  emergencyContact: EmergencyContact;
  employment: EmploymentInformation;
  questions: Record<string, boolean>;
  password: string;
}

export interface IncomeInformation {
  annualIncome?: number;
}

export interface uploadRentalApplicationFilesParams {
  rentalApplicationId: string;
  files: HydratedRentalApplicationFileDto;
}

export interface RentalApplicationIntents {
  intentId: string;
  clientSecret: string;
  stripeAccountId: string;
  totalAmount: number;
}

export interface EmploymentInformation {
  name: string;
  phone: string;
  position: string;
  salary?: number;
  startDate?: string;
  endDate?: string;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;

  [key: string]: any;
}

export enum TransUnionScreeningStatus {
  NotStarted = 'notStarted',
  Verified = 'verified',
  UnVerified = 'unVerified',
  ManualVerificationRequired = 'manualVerificationRequired',
}

export interface TransUnionScreening {
  status: TransUnionScreeningStatus;
  transunionScreeningId: number;
  transunionScreeningRequestRenterId: number;
  examId?: number;
  questions?: TransUnionExamQuestion[];
}

export interface TransUnionExamQuestion {
  questionKeyName: string;
  questionDisplayName: string;
  type?: string;
  choices?: TransUnionQuestionChoice[];
}

export interface TransUnionQuestionChoice {
  choiceKeyName?: string;
  choiceDisplayName?: string;
}

export enum MaritalStatus {
  Single = 'single',
  Married = 'married',
  Divorced = 'divorced',
  Widowed = 'widowed',
}

export interface DriversLicense {
  number?: string;
  state?: string;
  country?: string;
}

export interface EmergencyContact {
  name?: string;
  phone?: string;
  email?: string;
  relationship?: string;
}

export interface Pet {
  name?: string;
  breed?: string;
  weight?: string;
  age?: number;
  gender?: Gender;
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export interface RentalApplicationComment {
  comment: string;
  section: CommentSection;
}

export enum CommentSection {
  ApplicantInformation = 'applicantInformation',
  Questions = 'questions',
  IntrestedUnits = 'intrestedUnits',
  RentalHistory = 'rentalHistory',
  WorkHistory = 'workHistory',
  Screening = 'screening',
  Other = 'other',
}

export interface RentalApplication {
  credentials: RentalApplicationCredentials;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  ssn?: string;
  tenantId?: string;
  dateOfBirth?: string;
  applyingWith?: string[];
  interestedUnits?: string[];
  desiredMoveInDate?: string;
  maritalStatus?: MaritalStatus;
  driversLicense?: DriversLicense;
  annualIncome?: number;
  pets?: Pet[];
  residentialHistory?: RentalHistory[];
  emergencyContact?: EmergencyContact;
  employmentHistory?: EmploymentInformation[];
  comments?: RentalApplicationComment[];
  questions?: ScreeningQuestion[];
  createdAt?: string;
  updatedAt?: string;
  files?: HydratedRentalApplicationFileDto[];
  isDraft?: boolean;
  submittedAt?: string;
  requiresPayment?: boolean;
  screeningStatus?: TransUnionScreeningStatus;
  newFiles: FileDescriptor[];
  hasAcceptedMagicDoorTerms?: boolean;
  hasAcceptedTransUnionTerms?: boolean;
}

export interface ScreeningQuestion {
  question: string;
  answer: boolean;
}

export enum RentalApplicationErrorCode {
  InvalidFirstName,
  InvalidLastName,
  InvalidEmail,
  InvalidPhone,
  InvalidSSN,
  InvalidDateOfBirth,
  InvalidAnnualIncome,
  InvalidMagicDoorAgreement,
  InvalidTransUnionAgreement,
}

export enum RentalHistoryErrorCode {
  NoHistory,
  InvalidAddress,
  InvalidRentalHistoryStreetAddress1,
  InvalidRentalHistoryStreetAddress2,
  InvalidRentalHistoryReansonForLeaving,
  InvalidRentalHistoryCity,
  InvalidRentalHistoryState,
  InvalidRentalHistoryZipCode,
  InvalidRentalHistoryCountry,
  InvalidRentalHistoryLandlordPhone,
  InvalidRentalHistoryRent,
  InvalidRentalHistoryMoveInDate,
}

export enum EmploymentHistoryErrorCode {
  InvalidNameError,
  InvalidPhoneError,
  InvalidPositionError,
  InvalidStartDateError,
  InvalidEndDateError,
}

export interface ApplicationUpdateRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  ssn?: string;
  dateOfBirth?: string;
  files?: FileDescriptor[];
  interestedUnitIds?: string[];
  history: PresentableRentalHistory[];
  annualIncome?: string;
  employmentHistory: PresentableEmploymentInformation[];
  questions?: ScreeningQuestion[];
  hasAcceptedMagicDoorTerms?: boolean;
  hasAcceptedTransUnionTerms?: boolean;
}

export interface RentalHistory {
  address?: Address;
  landlordName?: string;
  landlordPhone?: string;
  rent?: number;
  moveInDate?: string;
  moveOutDate?: string;
  reasonForLeaving?: string;
}

export interface FileDescriptor {
  id: string;
  file: File;
  type: FileType;
  name: string;
  wasUploaded: boolean;
  isImage: boolean;
}

export enum ReportStatus {
  NotRequested = 'notRequested',
  Requested = 'requested',
  Available = 'available',
}

export enum ApplicationPaymentStatus {
  Unpaid = 'unpaid',
  Processing = 'processing',
  Paid = 'paid',
  Failed = 'failed',
}

export interface HydratedRentalApplicationFileDto {
  fileId: string;
  type: FileType;
  description?: string | null;
  fileName: string;
  fileExtension: string;
  fileUrl: string;
  thumbUrl?: string | null;
  contentType: string;
  fileSize: number;
}

export enum FileType {
  Identification = 'identification',
  BankStatement = 'bankStatement',
  EmploymentVerification = 'employmentVerification',
  Additional = 'additional',
}

export interface TransUnionAnswerSheet {
  examId?: number;
  answers?: TransUnionAnswer[];
}

export interface TransUnionAnswer {
  questionKeyName: string;
  selectedChoiceKeyName: string;
}
